import React, { useContext, useReducer } from "react";
import reducer from "./reducer";

const ThemeContext = React.createContext();

const initialState = {
  theme: "dark",
  color: "pink",
  message: {
    content: "",
    type: "",
    show: false,
  },
};

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const toggleTheme = () => {
    dispatch({ type: "TOGGLE_THEME" });
  };

  const handleMessage = (message, show) => {
    dispatch({ type: "SET_MESSAGE", message, show });
  };

  const blueSwitch = () => {
    dispatch({ type: "BLUE_SWITCH" });
  };

  const pinkSwitch = () => {
    dispatch({ type: "PINK_SWITCH" });
  };

  const yellowSwitch = () => {
    dispatch({ type: "YELLOW_SWITCH" });
  };

  return (
    <ThemeContext.Provider
      value={{
        ...state,
        toggleTheme,
        blueSwitch,
        pinkSwitch,
        yellowSwitch,
        handleMessage,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(ThemeContext);
};

export { ThemeContext, AppProvider };
