import React from "react";
import "../styles/Projects.css";
import crm from "../static/crm.png";
import restaurant from "../static/restaurant.png";
import spotify from "../static/spotify.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useGlobalContext } from "../context";

const Projects = () => {
  const { theme, color } = useGlobalContext();

  const projects = [
    {
      name: "Restaurant Site",
      image: restaurant,
      tags: ["html", "css", "js"],
      link: "https://vivekthapliyal4.github.io/restaurant-site/index.html",
      github: "https://github.com/vivekthapliyal4/restaurant-site",
    },
    {
      name: "Spotify Landing Page",
      image: spotify,
      tags: ["html", "css", "js"],
      link: "https://crm-restaurant.herokuapp.com/",
      github: "",
    },
    {
      name: "Customer Relationship Management",
      image: crm,
      tags: ["django", "python", "js"],
      link: "https://crm-restaurant.herokuapp.com/",
      github: "",
    },
  ];

  const options = {
    loop: true,
    nav: true,
    dots: false,
    autoplay: false,
    navText: ["Prev", "Next"],
    smartSpeed: 1000,
    margin: 50,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      1000: {
        items: 2,
      },
      1200: {
        items: 3,
      },
    },
  };
  return (
    <section className={`projects ${theme} ${color}`}>
      <div className="section-heading">
        <h2>Projects</h2>
      </div>
      <div className="project-items">
        <OwlCarousel className="owl-theme" {...options}>
          {projects.map(({ name, image, tags, link, github }, index) => (
            <div key={index} className="project-item">
              <div className="img-container">
                <img src={image} alt="" />
              </div>
              <div className="project-heading">
                <h4>
                  {name} <i className="fas fa-external-link"></i>
                </h4>
              </div>
              <div className="tech-stack">
                {tags.map((tag, index) => (
                  <span key={index} className="stack-item">
                    {tag}
                  </span>
                ))}
              </div>
              <div className="button-container">
                <a
                  href={github}
                  className="project-btn view-code"
                  target="_blank"
                  rel="noreferrer"
                >
                  Code
                </a>
                <a
                  href={link}
                  className="project-btn visit-site"
                  target="_blank"
                  rel="noreferrer"
                >
                  view site
                </a>
              </div>
            </div>
          ))}
        </OwlCarousel>
      </div>
    </section>
  );
};

export default Projects;
