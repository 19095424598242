import Message from "./components/Message";

const reducer = (state, action) => {
  if (action.type === "TOGGLE_THEME") {
    return { ...state, theme: state.theme === "light" ? "dark" : "light" };
    // let {theme, toggleTheme } = state;
    // const toggleTheme = () => {
    //     setTheme(theme === 'light' ? 'dark' : 'light')
    // }
  }
  if (action.type === "BLUE_SWITCH") {
    return { ...state, color: "blue" };
  }

  if (action.type === "PINK_SWITCH") {
    return { ...state, color: "pink" };
  }

  if (action.type === "YELLOW_SWITCH") {
    return { ...state, color: "yellow" };
  }
  if (action.type === "SET_MESSAGE") {
    return { ...state, ...Message, show: action.show, content: action.message };
  }

  throw new Error("no matching action type");
};

export default reducer;
