import React from "react";
import "../styles/Message.css";
import { useGlobalContext } from "../context";

const Message = () => {
  const { theme, content } = useGlobalContext();
  return (
    <div className={`${theme}`}>
      <div className="message">
        <p>{content}</p>
      </div>
    </div>
  );
};

export default Message;
