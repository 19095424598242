import React from 'react'
import { useGlobalContext } from '../context'
import "../styles/Skills.css"

const Skills = () => {
    const {theme, color} = useGlobalContext()
  return (
   <section className={`skills ${theme} ${color}`}>
       <div className="section-heading">
           <h2>Skills</h2>
       </div>
       <div className="skills-container">
           <div className="frontend-skills">
                <h3>Frontend</h3>
                <ul className="skill-list">
                    <li><i className="fab fa-html5"></i> HTML</li>
                    <progress value="98" max="100"></progress>
                    <li><i className="fab fa-css3"></i> CSS</li>
                    <progress value="95" max="100"></progress>
                    <li><i className="fab fa-js"></i> JavaScript</li>
                    <progress value="85" max="100"></progress>
                    <li><i className="fab fa-react"></i> React JS</li>
                    <progress value="80" max="100"></progress>
                    <li><i className="fab fa-bootstrap"></i> Bootstrap</li>
                    <progress value="70" max="100"></progress>
                </ul>
           </div>
           <div className="backend-skills">
                <h3>Backend</h3>
                <ul className="skill-list">
                    <li><i className="fab fa-node"></i> Node JS</li>
                    <progress value="80" max="100"></progress>
                    <li><b>ex</b> Express JS</li>
                    <progress value="70" max="100"></progress>
                    <li><i className="fab fa-envira"></i> Mongo DB</li>
                    <progress value="60" max="100"></progress>
                    <li><b>dj</b> Django</li>
                    <progress value="60" max="100"></progress>
                    <li><i className="fas fa-database"></i> SQL</li>
                    <progress value="70" max="100"></progress>
                </ul>
           </div>
           <div className="additional-skills">
                <h3>Tools</h3>
                <ul className="skill-list">
                    <li><i className="fab fa-git-alt"></i> Git</li>
                    <progress value="80" max="100"></progress>
                    <li><i className="fab fa-github"></i> GitHub</li>
                    <progress value="85" max="100"></progress>
                    <li><i className="fab fa-battle-net"></i> Redux</li>
                    <progress value="60" max="100"></progress>
                    <li><i className="fas fa-diagram-project"></i> Netlify</li>
                    <progress value="80" max="100"></progress>
                    <li><i className="fas fa-fire"></i> Firebase</li>
                    <progress value="40" max="100"></progress>
                </ul>
           </div>
       </div>
   </section>
  )
}

export default Skills