import React, { useRef, useEffect } from "react";
import "../styles/Contact.css";
import { useGlobalContext } from "../context";
import Message from "../components/Message";
import emailjs from "@emailjs/browser";
import { Link } from "react-router-dom";

const Contact = () => {
  const { theme, handleMessage, show } = useGlobalContext();
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_uqfcdd7",
        "template_cbf6jwk",
        form.current,
        process.env.REACT_APP_EMAILJS_USERID
      )
      .then(
        (result) => {
          handleMessage("Message sent successfully!", true);
          form.current.reset();
        },
        (error) => {
          handleMessage(error.text);
        }
      );
  };

  useEffect(() => {
    if (show) {
      const timer1 = setTimeout(() => {
        handleMessage("", false);
      }, 4000);

      return () => {
        clearTimeout(timer1);
      };
    }
  }, [handleMessage, show]);

  return (
    <>
      {/* {show ? <Message /> : null} */}
      {show && <Message />}
      <div className="contact-form">
        <form ref={form} onSubmit={sendEmail}>
          <div className="heading">Get In Touch</div>
          <ul className={`${theme}`}>
            <li className="half">
              <div>
                <input type="text" name="name" required />
                <label className="floating-label" htmlFor="name">
                  Name
                </label>
              </div>
              <div>
                <input type="email" name="email" required />
                <label className="floating-label" htmlFor="email">
                  Email
                </label>
              </div>
            </li>
            <li className="subject">
              <input type="text" name="subject" required />
              <label className="floating-label" htmlFor="subject">
                Subject
              </label>
            </li>
            <li>
              <textarea placeholder="Message" name="message"></textarea>
            </li>
            <li>
              <input className="btn contact-btn" type="submit" value="SEND" />
            </li>
          </ul>
          <Link className="back-home" to={"/"}>
            <i className="fa fa-arrow-left"></i> Back to Home
          </Link>
        </form>
      </div>
    </>
  );
};

export default Contact;
