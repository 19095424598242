import React from 'react'
import '../styles/Hero.css'
import kid from '../static/kid.png'
import Resume from '../static/Resume.pdf'
import { Link } from 'react-router-dom'

const Hero = () => {
    return (
        <div className="section-center">
            <article className="sm-img">
                <img src={kid} alt="" />
            </article>
            <article className="info">
                <div className="underline"></div>
                <h1>i'm vivek</h1>
                <h4>fullstack developer | web developer</h4>
                <Link to={'/contact'} className="btn contact-btn">contact</Link>
                <a className='btn resume-btn' href={Resume} target="_blank" rel='noreferrer'><i className="fas fa-arrow-down"></i> Resume</a>
                {/* social icons  */}
                <ul className="social-icons">
                    <li>
                        <a title="facebook" href="https://www.facebook.com/viveks.thapliyal" className="social-icon">
                            <i className="fab fa-facebook"></i>
                        </a>
                    </li>
                    <li>
                        <a title="linkedin" href="https://in.linkedin.com/in/vivek-thapliyal-603175207" className="social-icon">
                            <i className="fab fa-linkedin"></i>
                        </a>
                    </li>
                    <li>
                        <a title="twitter" href="https://www.twitter.com/vivekthapliyal_" className="social-icon">
                            <i className="fab fa-twitter"></i>
                        </a>
                    </li>
                    <li>
                        <a title="instagram" href="https://www.instagram.com/vivekthapliyal_" className="social-icon">
                            <i className="fab fa-instagram"></i>
                        </a>
                    </li>
                    <li>
                        <a title="github" href="https://www.github.com/vivekthapliyal4" className="social-icon">
                            <i className="fab fa-github"></i>
                        </a>
                    </li>
                </ul>
            </article>
            <article className="home-img">
                <img src={kid} alt="" />
            </article>
        </div>
    )
}

export default Hero