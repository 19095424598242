import React from "react";
import "../styles/Header.css";
import { useGlobalContext } from "../context";

const Header = () => {
  const { color, theme, toggleTheme, blueSwitch, pinkSwitch, yellowSwitch } =
    useGlobalContext();
  // this can be done with setting a binary value like true or false
  return (
    <nav className="navbar">
      <div className="dark-mode">
        <input
          onChange={toggleTheme}
          id="dark"
          type="checkbox"
          name="dark"
          title="dark"
          checked={theme === "dark"}
        />
        <label htmlFor="dark" className="label">
          <i className="fas fa-moon"></i>
          <i className="fas fa-sun"></i>
          <div className="ball"></div>
        </label>
      </div>
      <div className="colors">
        <h4 className="theme">Theme: </h4>
        <ul>
          <button
            className={`color blue-switch ${color === "blue" ? "active" : ""}`}
            onClick={blueSwitch}
          ></button>
          <button
            className={`color yellow-switch ${
              color === "yellow" ? "active" : ""
            }`}
            onClick={yellowSwitch}
          ></button>
          <button
            className={`color pink-switch ${color === "pink" ? "active" : ""}`}
            onClick={pinkSwitch}
          ></button>
        </ul>
      </div>
    </nav>
  );
};

export default Header;
