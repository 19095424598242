import React from 'react';
import { BrowserRouter as Router, Route, Routes as Switch } from 'react-router-dom';
import Header from './components/Header';
import './App.css';
import Home from './routers/Home';
import Contact from './routers/Contact';
import { useGlobalContext } from './context';



function App() {
const {theme, color} = useGlobalContext();
  return (
    <main className={`App ${theme} ${color}`}>  
    <Router>
      <Header />
      <Switch>
        <Route exact path="/" element={<Home/>} />
        <Route exact path="/contact" element={<Contact/>} />
        <Route path="*" element={<div>404</div>} />
      </Switch>
    </Router>
    </main>
  );
}

export default App;
